var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vedio-player" },
        [
          _c("revo-video-player", {
            ref: "vplayerRef",
            attrs: {
              videoType: _vm.videoType,
              direction: _vm.direction,
              url: _vm.videoUrl ? _vm.showVedioUrl : "",
            },
            on: { handleError: _vm.handleError },
          }),
        ],
        1
      ),
      _c(
        "a-tabs",
        {
          attrs: { "tab-position": _vm.mode },
          model: {
            value: _vm.activeCamera,
            callback: function ($$v) {
              _vm.activeCamera = $$v
            },
            expression: "activeCamera",
          },
        },
        _vm._l(_vm.cameras, function (item) {
          return _c("a-tab-pane", {
            key: item,
            attrs: { tab: item.cameraName },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }