<template>
  <div>
    <div class="vedio-player">
      <revo-video-player
        ref="vplayerRef"
        :videoType="videoType"
        @handleError="handleError"
        :direction="direction"
        :url="videoUrl ? showVedioUrl : ''"
      />
    </div>

    <a-tabs :tab-position="mode" v-model="activeCamera">
      <a-tab-pane v-for="item in cameras" :key="item" :tab="item.cameraName"></a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { Tabs } from 'ant-design-vue'
import api from '@/api/iot/vehicle'
import RevoVideoPlayer from '../../../RevoVideoPlayer'
export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    RevoVideoPlayer
  },
  props: {
    vehicleItem: {
      type: Object,
      default: () => {}
    },
    videoType: {
      type: String,
      default: 'rtmp'
    }
  },

  data() {
    return {
      url: api.videoUrlList[0],
      activeCamera: {},
      cameras: [],
      direction: '',
      videoUrl: null
    }
  },

  computed: {
    showVedioUrl() {
      if (!this.videoUrl) {
        return ''
      }
      if (this.videoUrl.indexOf('http') === 0) {
        return this.videoUrl
      } else {
        return this.url + this.videoUrl
      }
    }
  },
  watch: {
    'vehicleItem.vehicleId'(newVal, oldVal) {
      this.cameras = []
      this.activeCamera = null
      this.videoUrl = null
    },
    activeCamera(newVal, oldVal) {
      console.log('activeKey', newVal)
      // 先释放
      try {
        if (oldVal) {
          this.$refs.vplayerRef.reset()
        }
      } catch (e) {}
      if (newVal) {
        this.videoUrl = ''
        this.$nextTick(() => {
          this.videoUrl = newVal.cameraAddr
        })
      }
    }
  },
  mounted() {
    this.getFlv('init')
  },
  methods: {
    getFlv(type) {
      if (this.vehicleItem.cameras && this.vehicleItem.cameras.length === 0) {
        this.cameras = []
        this.activeCamera = null
        this.videoUrl = null
        return
      }
      postData(this.url, api.ffmpegStart, this.vehicleItem.cameras)
        .then(data => {
          console.log('视频流地址result:', data)
          if (type === 'init') {
            this.cameras = data.map(d => {
              d['cameraVisible'] = false
              if (d['mainCamera'] === 'yes') {
                console.log('mainCamera:', d)
                this.videoUrl = d['cameraAddr']
                this.activeCamera = d
                this.direction = d.id
              }
              return d
            })
          } else {
            for (const item of this.cameras) {
              if (item.id === type) {
                for (const dItem of data) {
                  if (item.id === dItem.id) {
                    if (item['mainCamera'] === 'yes') {
                      this.videoUrl = dItem.cameraAddr
                      this.activeCamera = dItem
                      this.direction = dItem.id
                    }
                    item.cameraAddr = dItem.cameraAddr
                    break
                  }
                }
              }
            }
          }
          this.cameras = data.map(d => {
            d['cameraVisible'] = false
            return d
          })
        }) // JSON from `response.json()` call
        .catch(error => console.error(error))
      function postData(url, requestPath, data) {
        const requestUrl = url + requestPath
        // Default options are marked with *
        return fetch(requestUrl, {
          body: JSON.stringify(data), // must match 'Content-Type' header
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, same-origin, *omit
          headers: {
            'content-type': 'application/json',
            // 'Authorization': 'Basic YWRtaW46YWRtaW4='
            Authorization: 'Basic YWRtaW46YWRtaW4jMjAyMDEyMTU='
          },
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer' // *client, no-referrer
        }).then(response => response.json()) // parses response to JSON
      }
    },
    handleError(item) {
      this.getFlv(item.direction)
    }
  }
}
</script>

<style lang="less">
.vehicle-monitor-vedio {
  .ant-tabs {
    text-align: center;
    margin-bottom: 3px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    border-radius: 5px;
    border: 1px solid;
    padding: 3px;
    margin: 3px;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-bar {
    margin: 0;
    border-bottom: 0;
  }
  .vedio-player {
    padding: 5px 5px 0 5px;
  }
  .ant-tabs-tab {
    min-width: 50px;
  }
}
</style>
